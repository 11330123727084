import React from 'react';
import Logo from '../../assets/Logo.png';
import '../CSS/BottomBranding.scss';

function BottomBranding() {
    return (
        <div className="ishasha-bottom-branding">
        <img src={Logo} alt="logo" />
    </div>
    );
}

export default BottomBranding;