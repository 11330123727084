import React from 'react';
import Masonry from 'react-masonry-css';
import room from '../../assets/room.png';
import '../CSS/MasonryComp.scss';

function MansonryComp(props) {

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
      };

    return (
<Masonry
breakpointCols={breakpointColumnsObj}
//   breakpointCols={5}
  className="my-masonry-grid"
  columnClassName="my-masonry-grid_column">
      <div>
      <img src={room} alt="room" />
      <p>Lorem nec atlorem vel curabitur nullam adipiscing ipsum tincidunt nullam sit laoreet amet libero amet tincidunt adipiscing vitae vitae nec libero a dolor enim viverra ipsum in sagittis euismod consectetur sagittis laoreet euismod libero elit</p>
          </div>
          <div>
      <img src={room} alt="room" />
      <p>Enim vel euismod nec in at lorem enim est nullam vitae vitae curabitur dolor consectetur nullam viverra dolor odio id atlorem adipiscing viverra auctor vel ipsum condimentum a laoreet amet id auctor tincidunt est libero amet dolor curabitur nec sit consectetur laoreet a nunc in tincidunt condimentum dolor id sit sagittis euismod libero ipsum libero elit id</p>
          </div>
          <div>
      <img src={room} alt="room" />
          </div>
          <div>
      <img src={room} alt="room" />
          </div>
          <div>
      <img src={room} alt="room" />
          </div>
          <div>
      <img src={room} alt="room" />
          </div>
</Masonry>

    );
}

export default MansonryComp;