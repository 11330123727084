import React from 'react';

function ThumbnailCarouselCard({imgPath, showDetailsClick}){
    return (
        <div className="ishasha-thumbnail-card">
            {showDetailsClick && (
                <div className="show-details-tag-container">
                <div className="show-details-tag has-text-centered">
                    <a href="/" className="view-details-link has-text-weight-bold">View details</a>
                </div>
                </div>
            )}
            <img src={imgPath} alt="thumbnail-carousel" />
        </div>
    )
}

export default ThumbnailCarouselCard;