import React from 'react';
import Logo from '../../assets/Logo.png';
import Booking from '../../assets/Booking.png';
import '../CSS/CarouselCard.scss';

function CarouselCard (props) {
    const { imgPath } = props;

    return (
        <div className="ishasha-carousel-card">
            <div className="ishasha-carousel-card-header">
                <div>
                <img src={Logo} alt="logo" className="ishasha-logo" />
                </div>
                <div>
                <img src={Booking} alt="book-now" className="ishasha-book-button" />
                </div>
            </div>
            <img src={imgPath} alt={imgPath} className="ishasha-carousel-card-image" />
        </div>
    )

}

export default CarouselCard;