import * as React from "react";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import '../CSS/ThumbnailCarousel.scss';
import ThumbnailCarouselCard from './ThumbnailCarouselCard';

class ThumbnailCarousel extends React.Component {

  responsive = {
    0: { items: 0 },
    1024: { items: 3 }
  };

  // thumbItem = (item, i) => (
  //   <span key={item} onClick={() => this.Carousel._onDotClick(i)}>
  //     *{" "}
  //   </span>
  // );

  render() {
    const items = this.props.paths.map(path => <div className="column" key={path.imgPath}><ThumbnailCarouselCard imgPath={path.imgPath} showDetailsClick={path.showClickDetails} /></div> )
    return (
      <div className="ishasha-thumbnail-carousel">
        <div className="columns">
        <AliceCarousel
          responsive={this.responsive}
          dotsDisabled={true}
          buttonsDisabled={true}
          items={items}
          ref={el => (this.Carousel = el)}
          autoPlayInterval={2000}
          autoPlay={true}
        />
        </div>
        {/* <nav>{items.map(this.thumbItem)}</nav> */}
        <button onClick={() => this.Carousel._slidePrev()} className="left-navigation" />
        <button onClick={() => this.Carousel._slideNext()} className="right-navigation" />
      </div>
    );
  }
}

export default ThumbnailCarousel;
