import React from 'react';
import GalleryCard from '../../shared/components/GalleryCard';
import room from '../../assets/room.png';
import Tea from '../../assets/Tea.png';
import activity from '../../assets/activity.png';
import NationalPark from '../../assets/NationalPark.png';
import Glassware from '../../assets/Glassware.png';
import Banner from '../../assets/Banner.png';

function GalleryOverview () {
    return (
        <div className="columns is-multiline">
            <GalleryCard galleryImagePath={NationalPark} galleryImageTag="National Parks" />
            <GalleryCard galleryImagePath={room} galleryImageTag="Hotels & Lodges" />
            <GalleryCard galleryImagePath={Tea} galleryImageTag="Packages" />
            <GalleryCard galleryImagePath={activity} galleryImageTag="Jobs & Activities" />
            <GalleryCard galleryImagePath={Glassware} galleryImageTag="Blog" />
            <GalleryCard galleryImagePath={Banner} galleryImageTag="About us" />
        </div>
    )
}

export default GalleryOverview;