import React from 'react';
import TopLanding from '../../shared/components/TopLanding';
import ThumbNailCarousel from '../../shared/components/ThumbnailCarousel';
import Footer from '../../shared/components/Footer';
import GalleryOverview from '../components/GalleryOverview';
import TreeLion from '../../assets/TreeLion.png';
import Birds from '../../assets/Birds.png';
import Bed from '../../assets/Bed.png';
import '../CSS/Landing.scss';

function Landing () {
    const paths = [{
        imgPath: Birds,
        showClickDetails: false
    },
    {
        imgPath: Bed,
        showClickDetails: false
    },
    {
        imgPath: TreeLion,
        showClickDetails: false
    }];

    return (
        <div>
            <TopLanding />
            <div className="landing-bar" />
            <div className="main-landing-content">
            <ThumbNailCarousel paths={paths} />
            <GalleryOverview />
            </div>
            <Footer />
        </div>
    )
}

export default Landing;