import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import NavBar from './NavBar';
import CarouselCard from './CarouselCard';
import Ribbon from './Ribbon';
import Lion from '../../assets/Lion.png'
import Gorillas from '../../assets/Gorillas.png'
import Scenery from '../../assets/Scenery.png'

function TopLanding(){
    const galleryItems = [<CarouselCard imgPath={Lion} />, <CarouselCard imgPath={Gorillas} />, <CarouselCard imgPath={Scenery} />]
    return (
        <div className="top-landing">
        <NavBar />

<AliceCarousel
items={galleryItems}
autoPlayInterval={4000}
autoPlayDirection="rtl"
autoPlay
fadeOutAnimation
buttonsDisabled
dotsDisabled
/>
<Ribbon />
    </div>

    );
}

export default TopLanding;