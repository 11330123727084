import React from 'react';
import TopLanding from '../../shared/components/TopLanding';
import MasonryComp from '../../shared/components/MansonryComp';
import ThumbnailCarousel from '../../shared/components/ThumbnailCarousel';
import Footer from '../../shared/components/Footer';
import BottomBranding from '../../shared/components/BottomBranding';
import Glassware from '../../assets/Glassware.png';
import Tea from '../../assets/Tea.png';
import room from '../../assets/room.png';
import '../CSS/Hotels.scss';


function Hotels(){
    
    const paths = [{
        imgPath: Glassware,
        showClickDetails: true
    },
    {
        imgPath: Tea,
        showClickDetails: true
    },
    {
        imgPath: room,
        showClickDetails: true
    }];


    return (
        <div>
            <TopLanding />
            <div className="main-hotel-content">
            <MasonryComp />
            <ThumbnailCarousel  paths={paths} />
            <BottomBranding />
            </div>
            <Footer />
        </div>
    );
}

export default Hotels;
