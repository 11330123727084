import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../CSS/NavBar.scss';


function NavBar() {

    const [isActive, setActive] = useState(false);
    return (
        <div className="safaris-navbar">
            <nav className="navbar is-transparent">
  <div className="navbar-brand">
    <div className={isActive ? 'navbar-burger burger is-active' : 'navbar-burger burger'} data-target="navbarExampleTransparentExample" onClick={() => setActive(!isActive)}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div id="navbarExampleTransparentExample" className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}>
    <div className="navbar-start">
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/" exact>HOME</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/parks">NATIONAL PARKS</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/hotels-lodges">HOTELS &amp; LODGES</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/packages">PACKAGES</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/activities">ACTIVITIES</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/gallery">GALLERY</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/blog">BLOG</NavLink>
      <NavLink className="navbar-item" activeClassName="active-navlink" to="/about">ABOUT US</NavLink>
    </div>
  </div>
</nav>
        </div>
    );
}

export default NavBar;