import React from 'react';
import SendMessage from '../../assets/send-message.png';

function Ribbon() {
    return (
        <div className="ishasha-ribbon">
                    <div className="level">
                        <div className="level-item has-text-centered">
            <i className="fab fa-youtube fa-2x"></i>
            </div>
            <div className="level-item has-text-centered">
                <p className="is-italic is-size-6 ishasha-slogan has-text-centered">"Experience the wonders of the world"</p>
            </div>
  <div className="level-item has-text-centered">
    <img src={SendMessage} alt="" style={{height: "40px"}} />
  </div>
</div>

        </div>
    )
}

export default Ribbon;