import React from 'react';
import '../CSS/Footer.scss';


function Footer() {
    return (
        <div className="ishasha-footer">
            <div className="footer-header" />
            <div className="footer-bottom">
                <div className="footer-bottom-item">
                    <p className="is-size-5 has-text-weight-bold">Ishasha Safaries</p>
                    <p>Ishasha safaris is a company located in East Africa providing the best safari packages for you and your family.
                         Add some more information here
                    </p>
                </div>
                <div className="footer-bottom-item">
                    <p className="is-size-5 has-text-weight-bold">Useful links</p>
                    <hr />
                    <p>Packages</p>
                    <p>National Parks</p>
                    <p>Hotels and Lodges</p>
                    <p>Blog</p>
                </div>
                <div className="footer-bottom-item">
                    <p className="is-size-5 has-text-weight-bold">Contact us</p>
                    <hr />
                    <p>Email: ishashaadventuresafari82@gmail.com</p>
                    <p>Tel: +256776532350</p>
                    <p>
                        <span className="social-icons">
                        <i className="fab fa-instagram" />
                        </span>
                        <span className="social-icons">
                        <i className="fab fa-facebook-square" />
                        </span>
                        <span className="social-icons">
                        <i className="fab fa-twitter-square" />
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;