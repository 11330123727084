import React from 'react';
import '../CSS/GalleryCard.scss';

function GalleryCard (props) {
    const { galleryImagePath, galleryImageTag } = props;
    return (
        <div className="column is-4">
        <div className="ishasha-gallery-card">
        <img src={galleryImagePath} alt="galleryImage" />
        <div className="gallery-tag-container">
        <div className="gallery-tag">
                <p className="has-text-centered">{galleryImageTag}</p>
            </div>
            </div>
        </div>
        </div>
    )
}

export default GalleryCard;
