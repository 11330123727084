import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Landing from './Landing/containers/Landing';
import NationalParks from './NationalParks/components/NationalParks';
import Hotels from './Hotels/components/Hotels';
import Gallery from './Gallery/components/Gallery';
import Packages from './Packages/components/Packages';
import Blog from './Blog/components/Blog';
import Activities from './Activities/components/Activities';
import About from './About/components/About';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/hotels-lodges" exact component={Hotels} />
          <Route path="/parks" exact component={NationalParks} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/packages" exact component={Packages} />
          <Route path="/blog" exact component={Blog} />
          <Route path="/about" exact component={About} />
          <Route path="/activities" exact component={Activities} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;