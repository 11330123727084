import React from 'react';
import TopLanding from '../../shared/components/TopLanding';

function Activties() {
    return (
        <div>
            <TopLanding />
        </div>
    )
}

export default Activties;
